import styled from 'styled-components'

export default styled.div`
  display: flex;
  .breadcrumb-item {
    max-width: 200px;
    position: relative;
    font-family: 'Prompt', sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-right: 13px;
    padding-left: 20px;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &:first-child {
      padding-left: 0px;
      &:before {
        display: none;
      }
    }
    &:last-child {
      color: ${(props) => props.theme.greenFont};
    }
    &:before {
      content: '>';
      position: absolute;
      left: 0px;
    }
  }
`
