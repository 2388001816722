import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import BreadcrumbStyled from './styledComponent'

class Breadcrumb extends PureComponent {
  generateBreadcrumb() {
    const breadcrumb = this.props.breadcrumbList.map((data) => {
      return (
        <div key={data.text} className="breadcrumb-item prevent-text-overflow" onClick={data.onClick}>
          {data.text}
        </div>
      )
    })
    return breadcrumb
  }

  render() {
    return <BreadcrumbStyled>{this.generateBreadcrumb()}</BreadcrumbStyled>
  }
}

Breadcrumb.propTypes = {
  breadcrumbList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })
  ).isRequired
}

export default Breadcrumb
